/*! =========================================================
  * NOTIFY MODAL
 * ========================================================= */
window.notifyService = window.notifyService    ||  {};

(function() {
    "use strict";

    this.show = function (title, message, type, notAutoHide) {
        var delay = 5000;
        if (notAutoHide) {
            delay = 0;
        }

        if (type == 'error') {
            type = 'danger';
        }

        var formatTitle = '';
        if (title != null && title != '') {
            formatTitle = '<span data-notify="title">{1}</span>'
        }
        var formatMessage = '';
        if (message != null && message != '') {
            formatMessage = '<span data-notify="message">{2}</span>';
        }

        var formarNotify = '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} text-center" role="alert">' +
            '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
            formatTitle +
            formatMessage +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>';
        $.notifyClose();
        $.notify({
                // options
                title: title,
                message: message,
                url: '',
                target: '_blank'
            },{
                // settings
                element: 'body',
                position: null,
                type: type,
                allow_dismiss: true,
                newest_on_top: true,
                showProgressbar: false,
                placement: {
                    from: "top",
                    align: "center"
                },
                offset: 20,
                spacing: 10,
                z_index: 1031,
                delay: delay,
                timer: 1000,
                url_target: '_blank',
                mouse_over: null,
                animate: {
                    enter: 'animated fadeInDown',
                    exit: 'animated fadeOutUp'
                },
                onShow: null,
                onShown: null,
                onClose: null,
                onClosed: null,
                icon_type: 'class',
                template: formarNotify,
            });
    }

    this.hide = function () {
        $.notifyClose();
    }

}).call(notifyService);
