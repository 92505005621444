
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
 // Cargamos el componente 'lodash' como variable global.
window._ = require('lodash');


try {
	// JQuery
    window.$ = window.jQuery = require('jquery');

    // Popper
    window.Popper = window.popper = require('popper.js').default;

    // SweetAlert
    window.Swal = require('sweetalert2');

    // Para que funcione en Internet Explorer
    window.Promise = require('promise-polyfill').default;

    // Material Dashboard bootstrap
    require('../js/template/bootstrap-material-design');

    // Bootstrap notify
    require('../js/template/bootstrap-notify');
    require('../js/apli/notifyservice');

    // Percect Scrollbar
	window.PerfectScrollbar = require('perfect-scrollbar').default;

} catch (e) {}

try {
    // jquery-validation.js
    require('jquery-validation');
} catch (e) {}